import React from 'react';

import './App.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';


import HomepageLayout from './Views/Homepage'
import MainLayout from './Views/Main';
import CourseLayout from './Views/Course';
import LibraryLayout from './Views/Library';
import CourseSelector from './Views/CourseSelector';
import ActivityLayout from './Views/Activity';
import LeaderboardLayout from './Views/Leaderboard';

const forceProd = true
const uri = process.env.NODE_ENV === 'production' || forceProd ? 'https://dataload.biggroup.cl/graphql' : 'http://localhost:4000/graphql'

const client = new ApolloClient({
  uri,
  cache: new InMemoryCache({
    addTypename: false
  })
});

function App() {
  return (
      <ApolloProvider client={client}>
        <Router>
          <div className="App">
            <Switch>
                <Route path="/" exact>
                  <HomepageLayout />
                </Route>
                <Route path="/courses/:token">
                  <MainLayout />
                </Route>
                <Route path="/courses_selector/:token/:type">
                  <CourseSelector />
                </Route>
                <Route path="/course/:id/:token">
                  <CourseLayout />
                </Route>
                <Route path="/library/:token">
                  <LibraryLayout />
                </Route>
                <Route path="/activity/:token">
                  <ActivityLayout />
                </Route>
                <Route path="/leaderboard/:token">
                  <LeaderboardLayout />
                </Route>
              </Switch>
          </div>
        </Router>
      </ApolloProvider>
  );
}

export default App;
