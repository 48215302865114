import { gql } from '@apollo/client'

export const USER_LOGIN = gql`
    mutation UserLogin ($UserLoginInput: UserLoginInput!) {
        UserLogin (input: $UserLoginInput) {
            success
            state
            text
            token
            GroupInfo{
                id
                userGroupId
                internalName
                name
            }
        }
    } 
`

export const RESOURCE_LIST = gql`
query resourceList ($ResourceListInput: ResourceListInput!) {
  resourceList (input: $ResourceListInput) {
    resources{
      id
      typeId
      name
      description
      parentId
      id
      image
      points
      json
      createdAt
      ResourceComplete{
        id
        answer
      }
    }
    points
    totalResources
    participantes
    participantesWord
    rankingPosition
  }
}
`

export const PERFORMANCE = gql`
query resourceList ($ResourceListInput: ResourceListInput!) {
  resourceList (input: $ResourceListInput) {
    points
    totalResources
    participantes
    participantesWord
    rankingPosition
    rankingPositionWord
  }
}
`

export const RESOURCE_COMPLETE = gql`
mutation resourceComplete ($ResourceCompleteInput: ResourceCompleteInput!) {
  resourceComplete (input: $ResourceCompleteInput) {
    success
  }
}
`