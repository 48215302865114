import React, {  } from 'react'
import {
  Menu,
  Segment,
  Header,
  Icon,
  Statistic,
} from 'semantic-ui-react'

import LogoProcasur from '../Assets/LogoProcasur.png'

import { useParams } from 'react-router-dom'

import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { PERFORMANCE } from './connection'

const LeaderboardLayout = () => {
  const { token } = useParams<any>()

  const { loading, data } = useQuery(PERFORMANCE, {
    variables: {
      ResourceListInput: {
        clientId: 3
      }
    },
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    fetchPolicy: 'no-cache'
  })

  const history = useHistory()

  if (loading) return <>Cargando tu información...</>;

  return <>
  <div>
  <Segment textAlign='left' basic className='HeaderLogo'>
    <img src={LogoProcasur} alt='Logo Coca Cola' />
  </Segment>
  </div>
  <div>
    <Menu inverted className='TopMenu'>
      <Menu.Item header>Plataforma de Aprendizaje Procasur</Menu.Item>
      <Menu.Menu position='right'>
        <Menu.Item
          icon='home'
          onClick={() => {
            history.push(`/courses_selector/${token}/courses`)
          }}
        />
      </Menu.Menu>
    </Menu>
    </div>
    <div className='MainContent MainContent--Library'>
        <div style={{
            display: "flex",
            width: '100%',
            justifyContent: 'space-between'
        }}>
        <Header as='h2'>
            <Icon name='line graph' />
            <Header.Content>
                Rendimiento
                <Header.Subheader>En esta sección encontrarás tu rendimiento en la plataforma</Header.Subheader>
            </Header.Content>
        </Header>

        <Statistic.Group style={{
            marginRight: 16
          }}>
            <Statistic>
              <Statistic.Value>{data?.resourceList?.points}</Statistic.Value>
              <Statistic.Label>Puntos</Statistic.Label>
            </Statistic>
            <Statistic>
            <Statistic.Value>{data?.resourceList?.totalResources}</Statistic.Value>
            <Statistic.Label>Tarjetas</Statistic.Label>
            </Statistic>

            <Statistic>
            <Statistic.Value text>
                {data?.resourceList?.participantesWord.split(' ').map((word: string) => {
                  return <>{word}<br /></>
                })}
            </Statistic.Value>
            <Statistic.Label>Participantes</Statistic.Label>
            </Statistic>

            <Statistic>
            <Statistic.Value>
                <Icon name='winner' />
            </Statistic.Value>
            <Statistic.Label>{data?.resourceList?.rankingPosition}° Lugar</Statistic.Label>
            </Statistic>
        </Statistic.Group>

        </div>
        
        {/* <List divided relaxed>
            <List.Item>
                <List.Icon name='github' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header as='a'>Procasur Demo User</List.Header>
                    <List.Description as='a'>{Number(localStorage.getItem(`${token}-points`))*30} puntos</List.Description>
                </List.Content>
            </List.Item>
            <List.Item>
                <List.Icon name='github' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header as='a'>Pablo Olmeño</List.Header>
                    <List.Description as='a'>{Number(localStorage.getItem(`${token}-points`))} puntos</List.Description>
                </List.Content>
            </List.Item>
            <List.Item>
            <List.Icon name='github' size='large' verticalAlign='middle' />
            <List.Content>
                <List.Header as='a'>Luis Moreno</List.Header>
                <List.Description as='a'>0 puntos</List.Description>
            </List.Content>
            </List.Item>
            <List.Item>
            <List.Icon name='github' size='large' verticalAlign='middle' />
            <List.Content>
                <List.Header as='a'>Julio Mena</List.Header>
                <List.Description as='a'>0 puntos</List.Description>
            </List.Content>
            </List.Item>
        </List> */}
    </div>

    
  </>
}
export default LeaderboardLayout