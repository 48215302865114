import React, { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

const MainLayout = () => {
  let { token } = useParams()
  const history = useHistory()

  useEffect(() => {
    history.push('/courses_selector/' + token + '/courses')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <>
  </>
}
export default MainLayout