import React, { useState, useEffect } from 'react'
import {
  Button,
  Icon,
  Menu,
  Segment,
  Form,
  Input,
  Transition,
  Dimmer,
  Loader,
  Modal,
  Header,
} from 'semantic-ui-react'

import LogoProcasur from '../Assets/LogoProcasur.png'

import { useMutation } from '@apollo/client';
import { USER_LOGIN } from './connection';
import { useHistory } from 'react-router-dom'

const HomepageLayout = () => {
  const [mail, setMail] = useState('')
  // const [code, setCode] = useState(0)

  const [modal, setModal] = useState(false)
  const [modalProps, setModalProps] = useState<any>({
    title: '',
    desc: 'El usuario indicado no es válido.'
})

  const [stateApp, setStateApp] = useState('INIT')

  const history = useHistory()

  const [userLogin,{ data: dataLogin, error: errorLogin, loading }] = useMutation(USER_LOGIN, {
    onError: (error) => {
      setModalProps({
        ...modalProps,
        desc: error.toString()
      })
      setModal(true)
    }
  })

  useEffect(() => {
    console.log({ errorLogin, dataLogin })
    if (errorLogin) {
        console.log({ errorLogin })
    }

    if (dataLogin) {
        const { UserLogin: { state, text } } = dataLogin
        
        // Code was sent to user.
        setStateApp(state)
        console.log({ text, state })
        if (state === 'OK_LOGIN') {
          history.push(`/courses/${dataLogin.UserLogin.token}`)
        }
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [dataLogin, errorLogin])

  
  return <>
  {loading && <Dimmer active>
    <Loader indeterminate>Cargando datos...</Loader>
  </Dimmer>}
  {modal && <Modal
      size='mini'
      onClose={() => setModal(false)}
      onOpen={() => setModal(true)}
      open={modal}
    >
      <Header as='h2'>
      <Icon name='warning sign' />
      <Header.Content>
        Error
        <Header.Subheader>Oops, ha ocurrido un problema</Header.Subheader>
      </Header.Content>
    </Header>
      <Modal.Content>
        <p>
          {modalProps.desc}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button color='red' inverted onClick={() => setModal(false)}>
          <Icon name='checkmark' /> Intentar nuevamente
        </Button>
      </Modal.Actions>
    </Modal>}
  <div>
  <Segment textAlign='left' basic className='HeaderLogo'>
    <img src={LogoProcasur} alt='Logo' />
  </Segment>
  </div>
  <div>
    <Menu inverted className='TopMenu'>
      <Menu.Item header>Plataforma de Aprendizaje Procasur</Menu.Item>
    </Menu>
    </div>
    <div style={{ padding: '3em 0em' }} className='MainContent'>
      <Segment inverted className="LoginBox">
        Bienvenidos a la plataforma de aprendizaje de Procasur.<br />
        Para comenzar, ingresa tu correo electrónico.

      <Transition.Group animation='fade left' duration={500}>
          {stateApp === 'INIT' && <Form
            inverted
            className='FormLogin'
            onSubmit={() => {
              // if (mail === '187311985') {
              //   history.push(`/courses/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjo0MzcsImZ1bGxOYW1lIjoiSnVsaW8gQW5kcsOpcyBNZW5hIFBpbnRvIiwiaTE4bklkIjoxLCJjb2RlIjo0NzQ4LCJjb3VudHJ5SWQiOjMsIm5pY2tuYW1lIjpudWxsLCJJMThuIjp7ImlkIjoxLCJuYW1lIjoiRW5nbGlzaCIsImVuZ2xpc2hOYW1lIjoiRW5nbGlzaCJ9LCJDb3VudHJ5Ijp7ImlkIjozLCJuYW1lIjoiQ2hpbGUifSwiR3JvdXBJbmZvIjp7ImlkIjo3MiwidXNlckdyb3VwSWQiOjQzNywiaW50ZXJuYWxOYW1lIjoiR3JvdXAgNzIiLCJuYW1lIjoiIn19LCJ0aW1lIjoiMjAyMC0xMC0wNFQwMDoyNToxNC42ODZaIiwiaWF0IjoxNjAxNzcxMTE0LCJleHAiOjE2MzMzMjg3MTR9.SMESRJJd9wHrTpbwl4tlzx_e48vmQ7lGlyyI8fAyA9g`)
              // } else {
              //   setModalProps({
              //     ...modalProps,
              //     desc: 'Error: Usuario no existe.'
              //   })
              //   setModal(true)
              // }
              userLogin({ variables: {
                UserLoginInput: {
                    mail
                }
            } })
            }}
            >
            <Form.Field>
              <Input
                icon='address card outline'
                iconPosition='left'
                onChange={(e, data) => {
                  setMail(data.value)
                }}
                placeholder='ejemplo@mail.com'
                size='big' />
            </Form.Field>
            <Button icon labelPosition='left' type='submit' size='big' color='blue'>
              <Icon name='hand point right outline' />
              Entrar
            </Button>
        </Form>}
      </Transition.Group>
      </Segment>
    </div>

    
  </>
}
export default HomepageLayout