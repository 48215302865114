import React, { useState, useEffect } from 'react'
import {
  Menu,
  Segment,
  Card,
  Image,
  Icon,
  Button,
  Transition,
  Dimmer,
  Header
} from 'semantic-ui-react'

import LogoProcasur from '../Assets/LogoProcasur.png'

import { useParams } from 'react-router-dom'

import { useHistory } from 'react-router-dom'

import { RESOURCE_LIST } from './connection'
import { useQuery } from '@apollo/client'
const CourseSelector = () => {
  // @ts-ignore
  const { token, type } = useParams()
  const { loading, data } = useQuery(RESOURCE_LIST, {
    variables: {
      ResourceListInput: {
        clientId: 3
      }
    },
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    fetchPolicy: 'no-cache'
  })

  const history = useHistory()

  const [onTutorial, setTutorial] = useState(localStorage.getItem('tutorial') === 'false' ? false : true)

  const audios = [
    'https://procasur.biggroup.cl/files/t1.mp3',
    'https://procasur.biggroup.cl/files/t2.mp3',
    'https://procasur.biggroup.cl/files/t3.mp3',
    'https://procasur.biggroup.cl/files/t4.mp3',
    'https://procasur.biggroup.cl/files/t5.mp3',
    'https://procasur.biggroup.cl/files/t6.mp3',
    'https://procasur.biggroup.cl/files/t7.mp3'
  ]

  const [step, setStep] = useState(-1)

  const [audio] = useState(new Audio(audios[0]));
  const [playing, setPlaying] = useState(false);
  const toggle = () => setPlaying(!playing);

  useEffect(() => {
      playing ? audio.play() : audio.pause();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [playing]
  );
  useEffect(() => {
    audio.volume = 1
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (step > -1 && step < 7) {
      if (playing) {
        setPlaying(false)
      }
      audio.src = audios[step]
      setPlaying(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  const [currentPoints, setCurrentPoints] = useState(0)

  const [visible, setVisible] = useState(new Array(10).fill(true))

  useEffect(() => {
    if (data && data?.resourceList?.points) {
      setCurrentPoints(data.resourceList.points)
    }
  }, [data])

  if (loading) return <>Cargando tu información...</>;
  
  const courses: any[] = data.resourceList.resources.map((resource: any) => {
    return {
      ...resource,
      status: resource.ResourceComplete ? 'Completado' : 'Disponible',
      url: `/course/${resource.id}/`
    }
  })
  
  return <>
  <Dimmer active={onTutorial} page>
    <Header as='h2' icon inverted style={{
      // marginBottom: 120
    }}>
      <Icon name='bolt' />
      Plataforma de Aprendizaje
      <Header.Subheader>
        Conoce la plataforma que acompañará toda tu experiencia de aprendizaje. <br />Activa tu audio para iniciar el recorrido.
      </Header.Subheader>

      {step === -1 && <>
        <Button color='red' style={{ marginTop: 24 }} onClick={() => {
          setStep(0)
        }}>
          Iniciar Tutorial
        </Button><br />
        <Button size='tiny' style={{ marginTop: 16 }} inverted onClick={() => {
          localStorage.setItem('tutorial', 'false')
          setTutorial(false)
        }}>
          Saltar Tutorial
        </Button>
      </>}
      {step > -1 && <><Button.Group inverted style={{ marginTop: 32 }}>
          <Button disabled={playing || !step} onClick={() => {
            if (step > 0) {
              setStep(step - 1)
            }
          }}>Anterior</Button>
          <Button.Or inverted text='o' />
          <Button disabled={playing} color={step < 6 ? 'green' : 'red'} onClick={() => {
            if (step < 6) {
              setStep(step + 1)
            } else {
              localStorage.setItem('tutorial', 'false')
              setTutorial(false)
            }
          }}>{step < 6 ? 'Siguiente' : 'Comenzar'}</Button>
      </Button.Group>
      <br />

      <Button disabled={playing} color='grey' style={{ marginTop: 24 }} size='mini' onClick={toggle}>
        Volver a Escuchar
        </Button></>}
    </Header>
      
  </Dimmer>
  <div>
  <Segment textAlign='left' basic className='HeaderLogo'>
    <img src={LogoProcasur} alt='Logo Coca Cola' />
  </Segment>
  </div>
  <div>
    <Menu inverted className='TopMenu'>
      <Menu.Item header></Menu.Item>
      <Menu.Menu position='right'>
          {/*<Menu.Item 
            name={`Bitácora`}
            icon='dashboard'
            onClick={() => {
              history.push(`/activity/${token}`)
            }}
          />*/}
          <Menu.Item 
            name={`Biblioteca`}
            icon='book'
            onClick={() => {
              history.push(`/library/${token}`)
            }}
          />
          <Menu.Item 
            name={`Rendimiento`}
            icon='line graph'
            onClick={() => {
              history.push(`/leaderboard/${token}`)
            }}
          />
          <Menu.Item
            name={`${currentPoints}`}
            icon='star'
            style={{
              paddingRight: 24
            }}
          />
          <Menu.Item
            name='Contacto'
            icon='envelope'
            style={{
              paddingRight: 24
            }}
            onClick={() => {
              window.location.href = 'mailto:tutora@biggroup.cl'
            }}
          />
          <Menu.Item
            icon='help circle'
            style={{
              paddingRight: 24
            }}
            onClick={() => {
              setTutorial(true)
            }}
          />
          <Menu.Item
            icon='home'
            style={{
              background: '#1971b9',
              paddingRight: 40
            }}
            onClick={() => {
              history.push(`/courses/${token}`)
            }}
          />
      </Menu.Menu>
    </Menu>
    </div>
    <div className='MainContent'>
  
        <div className="CourseSelectorContainer">
          
        <Card inverted style={{
          width: '100%',
          margin: 96,
          border: 0,
          marginTop: 48,
          marginBottom: 0,
          // background: 'rgba(0,0,0,0.6)',
          color: '#FFF'
        }}>
          <Card.Content>
            <Card.Description>
                Tablero de Aprendizaje
            </Card.Description>
            </Card.Content>
        </Card>
      

    {courses.map((course, ndx) => <Transition animation='tada' duration={300} visible={visible[ndx]}>
        <Card style={{opacity: course.status === 'Disponible' ? 1 : 0.5 }} onMouseEnter={() => {
            if (course.status === 'Disponible') {
                const update = [...visible]
                update[ndx] = !update[ndx]
                setVisible(update)
            }
        }}>
        {course.status === 'Completado' ? <Icon size='huge' style={{
          position: 'absolute',
          color: 'green',
          zIndex: 9999,
          width: '100%',
          marginTop: 72,
          textShadow: '#FFF 10px 10px 10px'
        }} name='check circle outline' /> : <></>}
        <Image src={course.image} wrapped ui={false} />
        <Card.Content>
        <Card.Header>{course.name}</Card.Header>
        {Number(course.points) > 0 ? <Card.Meta>
            <span className='date'>
                <Icon name='star' />
                {course.points} puntos
            </span>
        </Card.Meta> : <Card.Meta>
            <span className='date'>
                <Icon name='star' />&nbsp;
                6 herramientas
            </span>
        </Card.Meta>}
        <Card.Description>
            {type !== 'diplomas' ? course.description : course.status === 'Disponible' ? '¡Curso completado! Haz click en el botón para descargar tu certificado.' : 'Debes completar el curso para descargar el certificado.'}
        </Card.Description>
        </Card.Content>
        <Card.Content extra>
        {type !== 'diplomas' ? <Button color={course.status === 'Disponible' || course.status === 'Completado' ? 'green' : 'grey'} onClick={() => {
            // if (course.status === 'Disponible') {
              history.push(`${course.url}${token}`, {
                currentPoints
              })
            // }
        }}>
            {course.status === 'Disponible' ? 'Ir al curso' : course.status === 'Completado' ? 'Completado' : 'Próximamente'}
          </Button> : <Button color={course.status === 'Disponible' || course.status === 'Completado' ? 'green' : 'grey'} onClick={() => {
            if (course.status === 'Disponible'){
              window.location = course.certificate
            }
        }}>
            {course.status === 'Disponible' ? 'Descargar Certificado' : 'Próximamente'}
          </Button>}
        </Card.Content>
    </Card></Transition>)}

    {/*<Card inverted style={{
          width: '100%',
          margin: 96,
          border: 0,
          marginTop: 0,
          marginBottom: 48,
          // background: 'rgba(0,0,0,0.6)',
          color: '#FFF'
        }}>
          <Card.Content>
            <Card.Description>
                Pronto nuevos desafíos
            </Card.Description>
            </Card.Content>
      </Card>*/}

    </div>
    </div>

    
  </>
}
export default CourseSelector