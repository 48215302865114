import React, { useState, useEffect } from 'react'
import {
  Menu,
  Segment,
  Header,
  Icon,
  Card,
  Button,
} from 'semantic-ui-react'

import LogoProcasur from '../Assets/LogoProcasur.png'

import { useParams } from 'react-router-dom'

// import jwt_decode from 'jwt-decode'
import { useHistory } from 'react-router-dom'

const ActivityLayout = () => {
  const { id, token } = useParams()
  console.log({ id })
  const history = useHistory()

//   const { user } = jwt_decode(token)
//   const [firstName] = user.fullName.split(' ')

    const [answers, setAnswers] = useState<any[]>([])

    useEffect(() => {
        if (localStorage.getItem(`${token}-first-activity`) !== null){
            const rawAnswers = JSON.parse(localStorage.getItem(`${token}-first-activity`)!)
            const [,first,second,tercera] = rawAnswers
            setAnswers([
                first,
                second,
                tercera
            ])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return <>
  <div>
  <Segment textAlign='left' basic className='HeaderLogo'>
    <img src={LogoProcasur} alt='Logo Coca Cola' />
  </Segment>
  </div>
  <div>
    <Menu inverted className='TopMenu'>
      <Menu.Item header>Plataforma de Aprendizaje Procasur</Menu.Item>
      <Menu.Menu position='right'>
        <Menu.Item
          icon='home'
          onClick={() => {
            history.push(`/courses_selector/${token}/courses`)
          }}
        />
      </Menu.Menu>
    </Menu>
    </div>
    <div className='MainContent MainContent--Library'>
        <Header as='h2' style={{ paddingBottom: 32 }}>
            <Icon name='dashboard' />
            <Header.Content>
                Actividad
                <Header.Subheader>En esta sección encontrarás tu actividad en la plataforma</Header.Subheader>
            </Header.Content>
        </Header>

        {answers.length > 0 ? <Card.Group>
            <Card
                fluid
                color='red'
                description={answers[0]}
                header='¿Cuáles líneas de acción de estos componentes ha incorporado en su trabajo?' />
            <Card
                fluid
                color='orange'
                description={answers[1]}
                header='¿Cuáles líneas de acción no se han incorporado?' />
            <Card
                fluid
                color='yellow'
                description={answers[2]}
                header= '¿Cuáles líneas de acción han requerido ser adaptadas?' />
        </Card.Group> :  <Segment placeholder>
    <Header icon>
      <Icon name='discourse' />
      No hay actividad reciente en tu curso.
    </Header>
    <Button primary onClick={() => {
        history.push(`/courses_selector/${token}/course`)
    }}>¡Comienza ahora!</Button>
  </Segment>
}
    </div>

    
  </>
}
export default ActivityLayout