import React, {  } from 'react'
import {
  Menu,
  Segment,
  Header,
  Icon,
  List,
} from 'semantic-ui-react'

import LogoProcasur from '../Assets/LogoProcasur.png'

import { useParams } from 'react-router-dom'

import jwt_decode from 'jwt-decode'
import { useHistory } from 'react-router-dom'

const LibraryLayout = () => {
  const { id, token } = useParams<any>()
  console.log({ id })
  const history = useHistory()

  const { user } = jwt_decode(token)
  const [firstName] = user.fullName.split(' ')

  console.log({ firstName })

  return <>
  <div>
  <Segment textAlign='left' basic className='HeaderLogo'>
    <img src={LogoProcasur} alt='Logo Coca Cola' />
  </Segment>
  </div>
  <div>
    <Menu inverted className='TopMenu'>
      <Menu.Item header>Plataforma de Aprendizaje Procasur</Menu.Item>
      <Menu.Menu position='right'>
        <Menu.Item
          icon='home'
          onClick={() => {
            history.push(`/courses_selector/${token}/courses`)
          }}
        />
      </Menu.Menu>
    </Menu>
    </div>
    <div className='MainContent MainContent--Library'>
        <Header as='h2'>
            <Icon name='book' />
            <Header.Content>
                Biblioteca
                <Header.Subheader>En esta sección encontrarás los documentos de la biblioteca</Header.Subheader>
            </Header.Content>
        </Header>

        <List divided relaxed>
            <List.Item>
                <List.Icon name='file' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header as='a'><a href='https://procasur.biggroup.cl/files/media/1__Glosario__Modulo1.pdf'>Glosario: Módulo 1</a></List.Header>
                </List.Content>
            </List.Item>
            <List.Item>
                <List.Icon name='file' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header as='a'><a href='https://procasur.biggroup.cl/files/media/2__PuntosClaves__Modulo1.pdf'>Puntos Claves: Módulo 1</a></List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
                <List.Icon name='file' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header as='a'><a href='https://procasur.biggroup.cl/files/media/3__Estrategia_JR.pdf'>Estrategia de Jovenes Rurales</a></List.Header>
                </List.Content>
            </List.Item>
            <List.Item>
                <List.Icon name='file' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header as='a'><a href='https://procasur.biggroup.cl/files/media/4__Estrategia_JR_MAGA_081017.pdf'>Estrategia de Jovenes Rurales MAGA</a></List.Header>
                </List.Content>
            </List.Item>
            <List.Item>
                <List.Icon name='file word' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header as='a'><a href='https://procasur.biggroup.cl/files/media/5__ANEXOS_HERRAMIENTAS_DE_APRENDIZAJE.docx'>Anexos de las Herramientas de Aprendizaje</a></List.Header>
                </List.Content>
            </List.Item>
        </List>
    </div>

    
  </>
}
export default LibraryLayout